import axios from "axios";
import React from "react"
import {LSPEventPageBridge, LSPSmall} from "lsp_bridge"
import {punterLogin, getStream} from "../kambiClient.js"
import Reality from "./Reality.js"
import {urlParams, testFeed, isLobby} from "../utils.js"
import OnlyVideo from "./OnlyVideo";

export default class LSPEventPageDemo extends React.Component {

    constructor(props) {
        super(props)

        this.state = {playerShown: !!urlParams.get('eventId') || isLobby}

        /**
         * Example of init object
         * {
                lang: "en_GB",
                currency: "GBP",
                jurisdiction: "uk",
                market: "GB",
                offering: "ubuk",
                kambiOfferingApiBaseUrl: "https://eu.offering-api.kambicdn.com/offering/",
                kambiPlayerApiBaseURL: "https://al-auth.kambicdn.org/player",
                kambiPushWSUrl: "wss://push.aws.kambicdn.com",
                oddsFormat: "decimal", // fractional,american
                kambiEventId: 1007204481
            }
         */
        this.init = {
            lang: props.auth.lang,
            currency: props.auth.currency,
            jurisdiction: props.auth.jurisdiction,
            market: props.auth.market,
            offering: props.auth.offering,
            kambiOfferingApiBaseUrl: props.auth.kambiOfferingApiBaseUrl,
            kambiPlayerApiBaseURL: props.auth.kambiPlayerApiBaseURL,
            kambiPushWSUrl: props.auth.kambiPushWSUrl,
            oddsFormat: props.auth.oddsFormat,
            kambiEventId: props.activeEvent,
            mode: "SOFTLAUNCH",
            playerId: props.auth.playerId,
            valueStab: props.auth.valueStab,
        }

        this.devMock()
    }

    /**
     * Handling case when user pres back button
     */
    closePlayer(payload) {
        console.log("closePlayer", payload)
        this.props.setActiveEvent(null);
    }

    /**
     * Return link to hsl stream
     * iOS Safari do not support .mpd, so it must be only .m3u8 for iOS
     * @returns {string}
     */
    async getUrlOfVideoStream(eventId) {
        let res = await getStream(eventId || this.props.activeEvent)
        return res
    }

    /**
     * Return token for bet placing
     * @returns {string}
     */
    async getToken() {
        let res = await punterLogin()
        return res.token;
    }

    async getRoutingKey() {
        let res = await punterLogin()
        return res.routingKey;
    }

    /**
     * Return user balance object
     * Example:
     * {
     *      "bonus": 0.0,
     *      "cash": 34.6,
     *      "credit": 0.0,
     *      "currencyCode": "GBP",
     *      "grantedCredit": 0.0,
     *      "overdraft": 0.0,
     *      "restricted": 0.0,
     *      "total": 34.6
     *  }
     * @returns {object}
     */
    async getUserBalance() {
        let res = await axios.get(`${testFeed || '/'}wallitt/mainbalance`)
        return res.data.balance;
    }
    
    handleTracking(data){
        console.log(data.detail)
    }

    /**
     * React component to show in top of the player. Optional
     * @returns {(React.Component|boolean)}
     */
    overlayContent() {
        return false
    }

    /**
     *  Styled demo play button to launch the player.
     *  Already wrapped with onClick listener to start the player
     * @returns {HTMLDivElement}
     */
    demoPlayButtonDOMElement() {
        return (
            <div id={'container'}>
                <LSPSmall
                    eventId={this.props.activeEvent}
                    isFullscreenAvailable={true}
                    getUrlOfVideoStream={this.getUrlOfVideoStream.bind(this)}
                    loginCallback={()=>console.log('loginCallback')}
                    depositCallback={()=>console.log('depositCallback')}
                    goFullscreen={() => {this.setState({playerShown: true})}}
                    playerStateChanged={(data)=>console.log(data)}
                    pin={()=>console.log('pin')}
                    autoplay={true}
                />
            </div>
        )
    }

    legalCallback(key){
        if (key === 'gamblingCommission'){
            window.open(
                "https://beta.gamblingcommission.gov.uk/public-register/business/detail/45322",
                "_blank"
            );
        }else if (key === 'nlT&C'){
            console.log('nlT&C clicked')
        }
    }

    setDomain() {
        // for development
        if (urlParams.get('local'))
            return urlParams.get('local')
        if (process.env.NODE_ENV === 'development')
            return 'http://localhost:8081'
        if (window.location.host.includes("lsp."))
            return 'https://player.kingstats.dk'
        else
            return 'https://player-dev.kingstats.dk'
    }

    devMock(){
        let entries = {}
        urlParams.forEach((k,v) => {entries[v] = k})
        this.init = {...this.init, ...entries}
    }

    render() {
        if (urlParams.get('onlyStream') && urlParams.get('eventId')) {
            return (
                <OnlyVideo getUrlOfVideoStream={this.getUrlOfVideoStream.bind(this)} />
            )
        }
        return (
            <> {/* Unibet website */}
                {['SE', 'DK'].includes(this.init.market) && <Reality/> /* Regulatory bar */}
                {!this.setState.playerShown && this.demoPlayButtonDOMElement()}
                {this.state.playerShown && <LSPEventPageBridge
                    domain={this.setDomain()}
                    init={this.init}
                    closePlayer={this.closePlayer.bind(this)}
                    getUrlOfVideoStream={this.getUrlOfVideoStream.bind(this)}
                    getToken={this.getToken.bind(this)}
                    getRoutingKey={this.getRoutingKey.bind(this)}
                    getUserBalance={this.getUserBalance.bind(this)}
                    overlayContent={this.overlayContent.bind(this) /* Optional overlay content (React component) */}
                    handleTracking={this.handleTracking.bind(this)}
                    legalCallback={this.legalCallback.bind(this)}
                />}
            </>
        )
    }
}
